.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  .modal-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1001;
    transform: scale(0.7);
    animation: scaleUp 0.3s ease forwards;
  }
  
  @keyframes scaleUp {
    to {
      transform: scale(1);
    }
  }
  
  .modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: none;
    font-size: 25px;
    line-height: 1;
    color: #555;
    cursor: pointer;
    transition: color 0.2s;
  
    &:hover {
      color: #000;
    }
  }
  