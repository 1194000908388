/* CSS for DataTable */

.table-container {
    overflow-x: auto; /* Add horizontal scrollbar if table overflows */
  }
  
  .data-table {
    border-collapse: collapse; /* Collapse table borders */
    width: 100%;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd; /* Add a 1px solid border to th and td elements */
    padding: 8px; /* Add padding for better spacing */
    text-align: left; /* Align text to the left */
  }
  
  .data-table th {
    background-color: #f2f2f2; /* Add a background color to th elements */
  }
  